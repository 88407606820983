// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require js.cookie
// require cookie_law

// require("./stylesheets/site.scss")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import 'jquery'
//import 'popper.js'
import 'bootstrap';
//import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all"

//import '../spree/spree_variant_options';
//import '../spree/product_variant_options';
//import '../spree/variant_options_script.js.erb';

$(document).ready( function () {
    $(":submit").click(function() {
        $(this).parent().siblings('.spinner-border').removeClass('d-none');
    });
});

window.showSpinner = function() {
    $( "#collapseChangePrice .spinner-border" ).removeClass('d-none');
}
